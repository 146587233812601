<template>
  <p
    v-if="index === 0 || formatDate(currentDate) !== formatDate(prevDate)"
    class="px-2 py-1 font-[18px] text-black"
    :class="COLORS[stage]"
    data-testid="AuctionListItemDate"
  >
    {{ formatDate(currentDate) }}
  </p>
</template>

<script setup lang="ts">
import { formatDate as _formatDate } from '@autobid/ui/utils/date/formatDate'
import { getDayName } from '@autobid/ui/utils/date/getDayName'

interface Props {
  currentDate: string
  prevDate?: string
  index: number
  stage: string
}

defineProps<Props>()

const { locale } = useI18n()

const COLORS = {
  ONLINE: 'bg-[#B8CE21]',
  IN_PREPARATION: 'bg-[#F59C00]',
  FINISHED: 'bg-[#C1C1C1]'
}

function formatDate(dateIso: string) {
  return `${getDayName(dateIso, {
    lang: locale.value,
    weekday: 'long'
  })}, ${_formatDate(dateIso)}`
}
</script>
